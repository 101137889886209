<template>
  <BaseAZPopup v-model:show="show" :title="t('key.search.result.title.select.destination')" height="80vh">
    <div shadow="[0_4px_12px_0_#0E46380A]" bg="white" b="1.5 solid #00000014" px="4" h="12" flex="~ items-center" rounded="15" gap="2" mb="3">
      <form flex="1" @submit.prevent="linkToSearch">
        <input v-model="input" flex="1" w="100%" :placeholder="t('key.search.result.input.search.placeholder')" class="placeholder:text-#808080 placeholder:font-300" type="search" maxlength="100" enterkeyhint="search" @search="linkToSearch" />
      </form>

      <div v-if="input" w="5" h="5" flex="~ items-center justify-center" rounded="full" bg="#F5F5F5" class="azgo-haptics-feedback" @click="clearSearch">
        <NuxtIcon name="my-icon:close" class="#121212 azgo-hover-feedback text-xs" />
      </div>
      <div w="12" h="8" rounded="4" :style="{ background: isHome ? 'var(--tc-color-bg-index)' : 'var(--tc-color-primary)' }" flex="~ items-center justify-center" class="azgo-haptics-feedback" @click="linkToSearch">
        <NuxtIcon name="attr-icon:search" text="xl #fff" />
      </div>
    </div>

    <template v-if="citySearchHistory.length">
      <div text="lg #121212" font="500" mb="3">
        {{ t('key.search.destination.history') }}
      </div>
      <div mb="3">
        <van-radio-group direction="horizontal">
          <BaseAZRadioButton
            v-for="item in citySearchHistory"
            :key="typeof item === 'string' ? item : item.cityId"
            mb="2"
            :label="typeof item === 'string' ? item : item.cityName"
            :name="typeof item === 'string' ? item : item.cityId"
            @click="handleSelectCity(item)"
          >
          </BaseAZRadioButton>
        </van-radio-group>
      </div>
    </template>

    <div text="lg #121212" font="500" mb="3">
      {{ t('key.search.destination.featured') }}
    </div>
    <div>
      <van-radio-group direction="horizontal">
        <BaseAZRadioButton
          v-for="item in popularCityList"
          :key="item.cityId" mb="2"
          :label="item.cityName"
          :name="item.cityId"
          @click="handleSelectCity(item)"
        >
        </BaseAZRadioButton>
      </van-radio-group>
    </div>
  </BaseAZPopup>
</template>

<script lang="ts" setup>
import { api } from '~/apis/back/services'
import type { RegionSearchResponse } from '~/apis/back/types'
import { ENUM_PageName } from '~/enums'

const show = defineModel('show', {
  type: Boolean,
  default: false,
})

const { t } = useI18n()
const route = useRoute()
const input = ref(route.query.keyword as string || '')
const {
  citySearchHistory,
  searchHandler,
  clickHistory,
} = useSearchResult()
const { data: popularData } = api.regionPopularPost({})

const isHome = computed(() => {
  return route.meta.pageCategory === ENUM_PageName.Home
})

const popularCityList = computed(() => {
  return popularData.value?.data || []
})

function clearSearch() {
  input.value = ''
}

function handleSelectCity(item: RegionSearchResponse | string) {
  show.value = false
  return clickHistory(item)
}

function linkToSearch() {
  show.value = false
  return searchHandler(input.value, true)
}
</script>

<style>

</style>
