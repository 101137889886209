<template>
  <div class="relative" mt="-6" shadow="[0_4px_12px_0_#0E46380A]" mx="4" bg="white" b="1.5 solid #00000014" px="4" h="12" flex="~ items-center" rounded="15" gap="2">
    <form flex="1" @submit.prevent="linkToSearch">
      <input v-model="input" flex="1" w="100%" readonly :placeholder="t('key.search.result.input.search.placeholder')" class="placeholder:text-#808080 placeholder:font-300" type="search" maxlength="100" enterkeyhint="search" @search="linkToSearch" @click="show = true" />
    </form>

    <div v-if="input" w="5" h="5" flex="~ items-center justify-center" rounded="full" bg="#F5F5F5" class="azgo-haptics-feedback" @click="clearSearch">
      <NuxtIcon name="my-icon:close" class="#121212 azgo-hover-feedback text-xs" />
    </div>
    <div w="12" h="8" rounded="4" :style="{ background: isHome ? 'var(--tc-color-bg-index)' : 'var(--tc-color-primary)' }" flex="~ items-center justify-center" class="azgo-haptics-feedback" @click="linkToSearch">
      <NuxtIcon name="attr-icon:search" text="xl #fff" />
    </div>
    <MobileSearchDrawer v-model:show="show">
      <div class="relative" mt="-6" shadow="[0_4px_12px_0_#0E46380A]" mx="4" bg="white" b="1.5 solid #00000014" px="4" h="12" flex="~ items-center" rounded="15">
        <NuxtIcon name="attr-icon:search" class="mr-1" text="base" />
        <form flex="1" @submit.prevent="linkToSearch">
          <input v-model="input" flex="1" w="100%" :placeholder="t('key.search.result.input.search.placeholder')" class="placeholder:text-#808080 placeholder:font-300" type="search" maxlength="100" enterkeyhint="search" @search="linkToSearch" />
        </form>
      </div>
    </MobileSearchDrawer>
  </div>
</template>

<script lang="ts" setup>
import { ENUM_PageName } from '~/enums'

const route = useRoute()

const isHome = computed(() => {
  return route.meta.pageCategory === ENUM_PageName.Home
})

const { currentCity } = useCurrentCity()

const input = ref(isHome.value ? currentCity.value?.cityName : route.query.keyword as string || '')
const show = ref(false)
const { t } = useI18n()
const { searchHandler } = useSearchResult()
function clearSearch() {
  input.value = ''
  if (route.path.endsWith('/search-result')) {
    return linkToSearch()
  }
}

function linkToSearch() {
  return searchHandler(input.value, true)
}

watch(() => route.query.keyword, (newVal) => {
  input.value = newVal as string || ''
})
</script>

<style>

</style>
